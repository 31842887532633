import axios from '@axios';

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchQualifications(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/contractors/reports/qualifications', { params: queryParams })
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        }
    }
}