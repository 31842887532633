<template>
    
    <b-button
        :variant="variant"
        :type="type"
        :size="size"
        :block="block"
        :disabled="processing"
        :class="extraClass"
        @click="$emit('process-action')"
    >
        <div class="d-flex align-items-center justify-content-center" v-if="processing">
            <b-spinner small :variant="variantSpinner" />
            <b class="ml-1">PROCESANDO</b>
        </div>
        <b v-else>{{ text }}</b>
    </b-button>
            
</template>

<script>

    import { ref } from '@vue/composition-api';

    export default {
        props: {
            type: {
                type: String,
                default: 'button'
            },
            text: {
                type: String,
                required: true,
                default: null
            },
            size: {
                type: String,
                default: 'md'
            },
            block: {
                type: Boolean,
                default: false
            },
            processing: {
                type: Boolean,
                default: false
            },
            variant: {
                type: String,
                default: 'primary'
            },
            extraClass: {
                type: String,
                default: ''
            }
        },
        setup(props) {

            // REFS
            const variant = ref(props.variant);
            const variantSpinner = ref(null);

            variantSpinner.value = variant.value === 'light' ? 'dark' : 'light';

            return {
                variantSpinner
            }
        }
    }

</script>